jQuery(document).ready(function($) {
  require('./components/mobile-header.js');
  require('./components/slider.js');

  // Animate Process Steps
  var animatedSections = jQuery('.animated-step');
  var windowHeight = window.innerHeight;

  if (animatedSections.length > 0) {
    animatedSections.each(function () {
      var section = jQuery(this);
      var offsetTop = section.offset().top;

      var yearBox = $(section).next('.process-step-content')
      var yearBoxHeight = yearBox[0].scrollHeight

      if (window.pageYOffset >= offsetTop - windowHeight / 1.5) {
        jQuery(section).addClass('step-active');

        if($(this).hasClass('step-active')) {
          yearBox.css('max-height', `${yearBoxHeight}px`)
          yearBox.css('opacity', '1')
        }
      }
    });

    window.addEventListener('scroll', function () {

      animatedSections.each(function () {
        var section = jQuery(this);
        var offsetTop = section.offset().top;

        var yearBox = $(section).next('.process-step-content')
        var yearBoxHeight = yearBox[0].scrollHeight

        if (window.pageYOffset >= offsetTop - windowHeight / 1.5) {
          jQuery(section).addClass('step-active');

          if($(this).hasClass('step-active')) {
            yearBox.css('max-height', `${yearBoxHeight}px`)
            yearBox.css('opacity', '1')
          }
        }
      });
    });
  }
});